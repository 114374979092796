import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const REPORTS_BACKEND = "https://darkmatter.maytok.com/reporter/retrieve/";

export default new Router({
  routes: [
    {
      path: "/core",
      name: "core",
      component: () => import("@/view/layout/Layout"),
      children: [
        //<editor-fold desc="Brand">
        {
          path: "brand",
          name: "core_brand_list",
          component: () => import("@/view/pages/core/brand/Brand.vue"),
        },
        {
          path: "brand/create",
          name: "core_brand_create",
          component: () => import("@/view/pages/core/brand/BrandCreate.vue"),
        },
        {
          path: "/brand/edit/:id",
          name: "core_brand_edit",
          component: () => import("@/view/pages/core/brand/BrandCreate.vue"),
        },
        //</editor-fold>
        //<editor-fold desc="Buy">
        {
          path: "/buy",
          name: "core_buy_list",
          component: () => import("@/view/pages/core/buy/BuyList.vue"),
        },
        {
          path: "/buy/create",
          name: "core_buy_create",
          component: () => import("@/view/pages/core/buy/Buy.vue"),
        },
        {
          path: "/buy/:id",
          name: "core_buy_edit",
          component: () => import("@/view/pages/core/buy/Buy.vue"),
        },
        //</editor-fold>
        //<editor-fold desc="Category">
        {
          path: "/category",
          name: "core_category_list",
          component: () => import("@/view/pages/core/category/Category.vue"),
        },
        {
          path: "/category/create",
          name: "core_category_create",
          component: () =>
            import("@/view/pages/core/category/CategoryCreate.vue"),
        },
        {
          path: "/category/edit/:id",
          name: "core_category_edit",
          component: () =>
            import("@/view/pages/core/category/CategoryCreate.vue"),
        },
        //</editor-fold>
        //<editor-fold desc="Dispatch">
        {
          path: "/dispatch",
          name: "core_dispatch_list",
          component: () => import("@/view/pages/core/despatch/Despatch.vue"),
        },
        //</editor-fold>
        //<editor-fold desc="Group">
        {
          path: "/group",
          name: "core_group_list",
          component: () => import("@/view/pages/core/group/GroupList.vue"),
        },
        {
          path: "/group/create",
          name: "core_group_create",
          component: () => import("@/view/pages/core/group/GroupCreate.vue"),
        },
        {
          path: "/group/edit/:id",
          name: "core_group_edit",
          component: () => import("@/view/pages/core/group/GroupCreate.vue"),
        },
        //</editor-fold>
        //<editor-fold desc="ItemWarehouse">
        {
          path: "/item-warehouse",
          name: "core_item_warehouse_list",
          component: () =>
            import("@/view/pages/core/item-warehouse/ItemWarehouse"),
        },
        //</editor-fold>
        //<editor-fold desc="MachineId">
        {
          path: "/machine-id",
          name: "core_machine_id_create",
          component: () =>
            import("@/view/pages/core/machine-id/MachineIdCreate.vue"),
        },
        //</editor-fold>
        //<editor-fold desc="Product">
        {
          path: "/product/create",
          name: "core_product_create",
          component: () =>
            import("@/view/pages/core/product/ProductCreate.vue"),
        },
        {
          path: "/product",
          name: "core_product_list",
          component: () => import("@/view/pages/core/product/Product.vue"),
        },
        {
          path: "/product/edit/:id",
          name: "core_product_edit",
          component: () =>
            import("@/view/pages/core/product/ProductCreate.vue"),
        },
        //</editor-fold>
        //<editor-fold desc="Sale">
        {
          path: "sales",
          name: "core_sales_list",
          component: () => import("@/view/pages/core/sales/SalesList.vue"),
        },
        {
          path: "sales/create",
          name: "core_sales_create",
          component: () => import("@/view/pages/core/sales/SalesCreate.vue"),
        },
        //</editor-fold>
        //<editor-fold desc="Warehouse">
        {
          path: "/warehouse",
          name: "core_warehouse_list",
          component: () => import("@/view/pages/core/warehouse/Warehouse.vue"),
        },
        {
          path: "/warehouse/create",
          name: "core_warehouse_create",
          component: () =>
            import("@/view/pages/core/warehouse/WarehouseCreate.vue"),
        },
        {
          path: "/warehouse/edit/:id",
          name: "core_warehouse_edit",
          component: () =>
            import("@/view/pages/core/warehouse/WarehouseCreate.vue"),
        },
        //</editor-fold>
        //<editor-fold desc="Vehicle">
        {
          path: "vehicle",
          name: "core_vehicle_list",
          component: () => import("@/view/pages/core/vehicle/Vehicle.vue"),
        },
        {
          path: "vehicle/create",
          name: "core_vehicle_create",
          component: () =>
            import("@/view/pages/core/vehicle/VehicleCreate.vue"),
        },
        {
          path: "vehicle/edit/:id",
          name: "core_vehicle_edit",
          component: () =>
            import("@/view/pages/core/vehicle/VehicleCreate.vue"),
        },
        //</editor-fold>
      ],
    },
    {
      path: "/fuel",
      name: "fuel",
      component: () => import("@/view/layout/Layout"),
      children: [
        //<editor-fold desc="Dispenser">
        {
          path: "dispenser",
          name: "fuel_dispenser_list",
          component: () =>
            import("@/view/pages/fuel/dispenser/DispenserList.vue"),
        },
        {
          path: "dispenser/create",
          name: "fuel_dispenser_create",
          component: () =>
            import("@/view/pages/fuel/dispenser/DispenserCreateEdit.vue"),
        },
        {
          path: "dispenser/:id",
          name: "fuel_dispenser_edit",
          component: () =>
            import("@/view/pages/fuel/dispenser/DispenserCreateEdit.vue"),
        },
        {
          path: "dispenser/pos/:id",
          name: "fuel_dispenser_pos",
          component: () => import("@/view/pages/fuel/dispenser/POS.vue"),
        },
        //</editor-fold>
      ],
    },
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/core/client",
          name: "administration",
          component: () => import("@/view/pages/client/Client.vue"),
        },
        {
          path: "/core/client/create",
          name: "administration_create",
          component: () => import("@/view/pages/client/ClientCreate.vue"),
        },
        {
          path: "/enterprise",
          component: () =>
            import("@/view/pages/enterprise/SelectEnterprise.vue"),
          children: [
            {
              path: "/",
              name: "enterprise_select",
              component: () =>
                import("@/view/pages/enterprise/SelectEnterprise.vue"),
            },
          ],
        },
        //<editor-fold desc="User">
        {
          path: "/core/user",
          name: "user_list",
          component: () => import("@/view/pages/user/User.vue"),
        },
        {
          path: "/core/user/create",
          name: "user_create",
          component: () => import("@/view/pages/user/UserCreate.vue"),
        },
        {
          path: "/core_user/edit/:id",
          name: "user_edit",
          component: () => import("@/view/pages/user/UserCreate.vue"),
        },
        //</editor-fold>
        //<editor-fold desc="Person">
        {
          path: "/person",
          name: "person",
          component: () => import("@/view/pages/person/Person.vue"),
        },
        {
          path: "/person/create",
          name: "person_create",
          component: () => import("@/view/pages/person/PersonCreate.vue"),
        },
        {
          path: "/person/edit/:id",
          name: "person_edit",
          component: () => import("@/view/pages/person/PersonCreate.vue"),
        },
        //</editor-fold>

        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
        },
        {
          path: "/builder",
          name: "builder",
          component: () => import("@/view/pages/Builder.vue"),
        },
        {
          path: "/vue-bootstrap",
          name: "vue-bootstrap",
          component: () =>
            import("@/view/pages/vue-bootstrap/VueBootstrap.vue"),
          children: [
            {
              path: "alert",
              name: "vue-bootstrap-alert",
              component: () => import("@/view/pages/vue-bootstrap/Alert.vue"),
            },
            {
              path: "badge",
              name: "vue-bootstrap-badge",
              component: () => import("@/view/pages/vue-bootstrap/Badge.vue"),
            },
            {
              path: "button",
              name: "vue-bootstrap-button",
              component: () => import("@/view/pages/vue-bootstrap/Button.vue"),
            },
            {
              path: "button-group",
              name: "vue-bootstrap-button-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/ButtonGroup.vue"),
            },
            {
              path: "button-toolbar",
              name: "vue-bootstrap-button-toolbar",
              component: () =>
                import("@/view/pages/vue-bootstrap/ButtonToolbar.vue"),
            },
            {
              path: "card",
              name: "vue-bootstrap-card",
              component: () => import("@/view/pages/vue-bootstrap/Card.vue"),
            },
            {
              path: "carousel",
              name: "vue-bootstrap-carousel",
              component: () =>
                import("@/view/pages/vue-bootstrap/Carousel.vue"),
            },
            {
              path: "collapse",
              name: "vue-bootstrap-collapse",
              component: () =>
                import("@/view/pages/vue-bootstrap/Collapse.vue"),
            },
            {
              path: "dropdown",
              name: "vue-bootstrap-dropdown",
              component: () =>
                import("@/view/pages/vue-bootstrap/Dropdown.vue"),
            },
            {
              path: "embed",
              name: "vue-bootstrap-embed",
              component: () => import("@/view/pages/vue-bootstrap/Embed.vue"),
            },
            {
              path: "form",
              name: "vue-bootstrap-form",
              component: () => import("@/view/pages/vue-bootstrap/Form.vue"),
            },
            {
              path: "form-checkbox",
              name: "vue-bootstrap-form-checkbox",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormCheckbox.vue"),
            },
            {
              path: "form-file",
              name: "vue-bootstrap-form-file",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormFile.vue"),
            },
            {
              path: "form-group",
              name: "vue-bootstrap-form-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormGroup.vue"),
            },
            {
              path: "form-input",
              name: "vue-bootstrap-form-input",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormInput.vue"),
            },
            {
              path: "form-radio",
              name: "vue-bootstrap-form-radio",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormRadio.vue"),
            },
            {
              path: "form-select",
              name: "vue-bootstrap-form-select",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormSelect.vue"),
            },
            {
              path: "form-textarea",
              name: "vue-bootstrap-form-textarea",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormTextarea.vue"),
            },
            {
              path: "image",
              name: "vue-bootstrap-image",
              component: () => import("@/view/pages/vue-bootstrap/Image.vue"),
            },
            {
              path: "input-group",
              name: "vue-bootstrap-input-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/InputGroup.vue"),
            },
            {
              path: "jumbotron",
              name: "vue-bootstrap-jumbotron",
              component: () =>
                import("@/view/pages/vue-bootstrap/Jumbotron.vue"),
            },
            {
              path: "layout-grid-system",
              name: "vue-bootstrap-layout-grid-system",
              component: () =>
                import("@/view/pages/vue-bootstrap/LayoutGridSystem.vue"),
            },
            {
              path: "link",
              name: "vue-bootstrap-link",
              component: () => import("@/view/pages/vue-bootstrap/Link.vue"),
            },
            {
              path: "list-group",
              name: "vue-bootstrap-list-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/ListGroup.vue"),
            },
            {
              path: "media",
              name: "vue-bootstrap-media",
              component: () => import("@/view/pages/vue-bootstrap/Media.vue"),
            },
            {
              path: "modal",
              name: "vue-bootstrap-modal",
              component: () => import("@/view/pages/vue-bootstrap/Modal.vue"),
            },
            {
              path: "nav",
              name: "vue-bootstrap-nav",
              component: () => import("@/view/pages/vue-bootstrap/Nav.vue"),
            },
            {
              path: "navbar",
              name: "vue-bootstrap-navbar",
              component: () => import("@/view/pages/vue-bootstrap/Navbar.vue"),
            },
            {
              path: "pagination",
              name: "vue-bootstrap-pagination",
              component: () =>
                import("@/view/pages/vue-bootstrap/Pagination.vue"),
            },
            {
              path: "pagination-nav",
              name: "vue-bootstrap-pagination-nav",
              component: () =>
                import("@/view/pages/vue-bootstrap/PaginationNav.vue"),
            },
            {
              path: "notify-popover",
              name: "vue-bootstrap-popover",
              component: () => import("@/view/pages/vue-bootstrap/Popover.vue"),
            },
            {
              path: "notify-toasts",
              name: "vue-bootstrap-toasts",
              component: () => import("@/view/pages/vue-bootstrap/Toasts.vue"),
            },
            {
              path: "notify-tooltip",
              name: "vue-bootstrap-tooltip",
              component: () => import("@/view/pages/vue-bootstrap/Tooltip.vue"),
            },
            {
              path: "progress",
              name: "vue-bootstrap-progress",
              component: () =>
                import("@/view/pages/vue-bootstrap/Progress.vue"),
            },
            {
              path: "progress-spinner",
              name: "vue-bootstrap-spinner",
              component: () => import("@/view/pages/vue-bootstrap/Spinner.vue"),
            },
            {
              path: "table",
              name: "vue-bootstrap-table",
              component: () => import("@/view/pages/vue-bootstrap/Table.vue"),
            },
            {
              path: "tabs",
              name: "vue-bootstrap-tabs",
              component: () => import("@/view/pages/vue-bootstrap/Tabs.vue"),
            },
          ],
        },
        {
          path: "/custom-plugins",
          name: "plugins",
          component: () => import("@/view/pages/plugins/Plugins.vue"),
          children: [
            {
              path: "cropper",
              name: "cropper",
              component: () => import("@/view/pages/plugins/Cropper.vue"),
            },
            {
              path: "treeselect",
              name: "treeselect",
              component: () => import("@/view/pages/plugins/Treeselect.vue"),
            },
          ],
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/profile/Profile.vue"),
          children: [
            {
              path: "profile-1",
              name: "profile-1",
              component: () => import("@/view/pages/profile/Profile-1.vue"),
            },
            {
              path: "profile-2",
              name: "profile-2",
              component: () => import("@/view/pages/profile/Profile-2.vue"),
            },
            {
              path: "profile-3",
              name: "profile-3",
              component: () => import("@/view/pages/profile/Profile-3.vue"),
            },
            {
              path: "profile-4",
              name: "profile-4",
              component: () => import("@/view/pages/profile/Profile-4.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/reports",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/media/client/report",
          name: "media_client_report",
          beforeEnter() {
            location.href = `${REPORTS_BACKEND}?report=customer_list&ruc=${localStorage.getItem(
              "id_ruc"
            )}`;
          },
        },
        {
          path: "/media/client/kardex",
          name: "media_kardex",
          beforeEnter() {
            location.href = "/media/reports/KARDEX.xls";
          },
        },
        {
          path: "/media/client/kardex",
          name: "media_kardex",
          beforeEnter() {
            location.href =
              "/media/reports/LE2060100409820221000140100001111.txt";
          },
        },
        {
          path: "/media/client/contasis",
          name: "media_contasis",
          beforeEnter() {
            location.href = "/media/reports/contasis.xlsx";
          },
        },
        {
          path: "/media/client/compras",
          name: "media_shopping",
          beforeEnter() {
            location.href = "/media/reports/Compras.xls";
          },
        },
        {
          path: "/media/client/ventas",
          name: "media_sales",
          beforeEnter() {
            location.href = "/media/reports/Ventas.xls";
          },
        },
      ],
    },
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue"),
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue"),
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue"),
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue"),
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue"),
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue"),
        },
      ],
    },
    {
      path: "/logistics",
      name: "logistics",
      component: () => import("@/view/layout/Layout"),
      children: [
        //<editor-fold desc="Envíos">
        {
          path: "dispatch",
          name: "logistics_dispatch_list",
          component: () =>
            import("@/view/pages/logistics/dispatch/DispatchList.vue"),
        },
        {
          path: "dispatch/create",
          name: "logistics_dispatch_create",
          component: () =>
            import("@/view/pages/logistics/dispatch/DispatchCreate.vue"),
        },
        {
          path: "/dispatch/edit/:id",
          name: "logistics_dispatch_edit",
          component: () =>
            import("@/view/pages/logistics/dispatch/DispatchCreate.vue"),
        },
        //</editor-fold>
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});

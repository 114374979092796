const ID_TOKEN_KEY = "id_token";
const ID_REFRESH_KEY = "id_refresh";
const ID_RUC = "id_ruc";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getRefresh = () => {
  return window.localStorage.getItem(ID_REFRESH_KEY);
};

export const saveToken = (token) => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
  window.localStorage.setItem(ID_RUC, decodeToken().ruc);
};

export const saveRefresh = (token) => {
  window.localStorage.setItem(ID_REFRESH_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(ID_REFRESH_KEY);
};

export const decodeToken = () => {
  const token = getToken();
  if (!token) {
    return null;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

export default {
  getToken,
  getRefresh,
  saveToken,
  saveRefresh,
  destroyToken,
  decodeToken,
};
